/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from "react";
import {Redirect} from "react-router-dom";

import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";

const routes = [
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/login"/>
    },
    {
        path: "/",
        component: AuthLayout,
        routes: [
            {
                path: "/invite",
                exact: true,
                component: lazy(() => import("views/Invite"))
            },
            {
                path: "/login",
                exact: true,
                component: lazy(() => import("views/Login"))
            },
            {
                path: "/register",
                exact: true,
                component: lazy(() => import("views/Register"))
            },
            {
                path: "/dashboard",
                exact: true,
                component: lazy(() => import("views/Dashboard"))
            },
            {
                path: "/register/confirmation",
                exact: true,
                component: lazy(() => import("views/Confirmation"))
            },
            {
                path: "/forgotPassword",
                exact: true,
                component: lazy(() => import("views/ForgotPassword"))
            },
            {
                path: "/settings",
                exact: true,
                component: lazy(() => import("views/Settings"))
            },
            {
                path: "/settings/changePassword",
                exact: true,
                component: lazy(() => import("views/ChangePassword"))
            },
            {
                component: () => <Redirect to="/errors/error-404"/>
            }
        ]
    },
    {
        path: "/errors",
        component: ErrorLayout,
        routes: [
            {
                path: "/errors/error-401",
                exact: true,
                component: lazy(() => import("views/Error401"))
            },
            {
                path: "/errors/error-404",
                exact: true,
                component: lazy(() => import("views/Error404"))
            },
            {
                path: "/errors/error-500",
                exact: true,
                component: lazy(() => import("views/Error500"))
            },
            {
                component: () => <Redirect to="/errors/error-404"/>
            }
        ]
    },
];

export default routes;
