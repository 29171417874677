import {get, post, rawPost} from "./api";

export const getUserProfile = async () => {
    try {
        return await get("Auth", "/user");
    } catch (error) {
        return {
            error,
        }
    }
};

/*
  User:
    - email
    - phone
    - firstName
    - lastName
 */
export const registerUser = async (user) => {
    try {
        return await post("Auth", "/register", {
            email: user.attributes.email,
            phone: user.attributes["custom:phone"],
            firstName: user.attributes["custom:firstName"],
            lastName: user.attributes["custom:lastName"],
        }, {
            "Authorization": `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            "Content-Type": "application/json",
        });
    } catch (error) {
        return {
            error,
        }
    }
};

/*
  Code:
     - id
 */
export const acceptInvite = async (id) => {
    try {
        await post("Auth", `/user/invitation/${id}`);
        return {
            success: "Successfully accepted invitation",
        }
    } catch (error) {
        return {
            error,
        }
    }
};

export const auditLogSuccess = async () => {
    try {
        return await post("Auth", "/user/login/success", {});
    } catch (error) {
        return {
            error,
        }
    }
};

export const auditLogFail = async (userInfo) => {
    try {
        return await rawPost("Auth", "/failedlogin", {
            userEmail: userInfo.email
        });
    } catch (error) {
        return {
            error,
        }
    }
};
