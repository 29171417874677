import {
    USER_PROFILE_LOAD_PROFILE,
    USER_PROFILE_SET_CLIENT_CONTEXT,
    USER_SIGN_OUT,
    SAVE_FORM_VALUES,
} from "../actions";

const initState = {
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    clientContext: null,
    clients: [],
};

const set_client_context = (state, {clientContext}) => ({...state, clientContext});
const load_user_profile = (state, {firstName, lastName, mobilePhone, email, clients, userRole}) => {
    return {
        ...state,
        clientContext: clients && clients.length === 1 ? clients[0] : state.clientContext,
        clients: [...clients],
        firstName,
        lastName,
        mobilePhone,
        email,
        userRole,
    };
};
const sign_out_user = (state, {}) => {
    return {
        ...state,
        ...initState,
    };
};

export default (state, action) => {
    switch (action.type) {
        case USER_PROFILE_LOAD_PROFILE:
            return load_user_profile(state, action);
        case USER_SIGN_OUT:
            return sign_out_user(state, action);
        case USER_PROFILE_SET_CLIENT_CONTEXT:
            return set_client_context(state, action);
        case SAVE_FORM_VALUES:
            return {...state, ...action};
        default:
            return state;
    }
};
