import React, {useContext, useEffect, useState} from "react";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import {ThemeProvider} from "@material-ui/styles";
import {renderRoutes} from "react-router-config";
import {currentAuthenticatedUser} from "./services/auth-service";
import {GlobalContext} from "./contexts/context";
import theme from "./theme";
import routes from "./routes";
import {
    ScrollReset,
    GoogleAnalytics,
    CookiesNotification,
} from "./components";

// Aws config
import {
    API,
    Auth,
} from "./amplify";
import Amplify, {Hub} from "aws-amplify";

// Material-UI Components
import {
    makeStyles,
    CircularProgress,
    CssBaseline,
    Grid,
    Typography,
} from "@material-ui/core";

// React-Router History Init
const history = createBrowserHistory();

// Amplify config
Amplify.configure({
    API,
    Auth,
});

const useStyles = makeStyles(() => ({
    progress: {
        height: "100vh",
    },
}));

const App = () => {
    const classes = useStyles();
    const [initializing, setInitializing] = useState(true);
    const context = useContext(GlobalContext);
    const currentUser = async () => {
        const user = await currentAuthenticatedUser();

        if (!user.error)
            await context.loadUserProfile();
        setInitializing(false);
    };

    // Listen for Amplify auth events
    useEffect(() => {
        Hub.listen("auth", (data) => {
            const {payload} = data;
            // console.log("EVENT", payload.event);
            // console.log("A new auth event has happened: ", data);
            // if (payload.event === "signIn") {
            //     console.log("a user has signed in!")
            // }
            // if (payload.event === "signOut") {
            //     console.log("a user has signed out!")
            // }
        });
        currentUser();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router history={history}>
                <ScrollReset/>
                <GoogleAnalytics/>
                {/*<CookiesNotification/>*/}
                {!initializing && context ? renderRoutes(routes) : (
                    <>
                        <Grid className={classes.progress}
                              container
                              direction={"column"}
                              justify={"center"}
                              alignItems={"center"}>
                            <Typography variant={"h2"}
                                        style={{marginBottom: 50}}>
                                Initializing App...
                            </Typography>
                            <CircularProgress size={150}/>
                        </Grid>
                    </>
                )}
            </Router>
        </ThemeProvider>
    );
};

export default App;
