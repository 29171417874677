import React, {Suspense} from "react";
import {renderRoutes} from "react-router-config";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";

// Material-UI Components
import {
    Grid,
    LinearProgress,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
        content: {
            height: "100vh",
        },
        container: {
            height: "90vh",
            [theme.breakpoints.down('md')]: {
                height: 'auto'
            }
        },
    }
));

const Auth = ({route}) => {
    const classes = useStyles();

    return (
        <>
            <main className={classes.content}>
                <Grid container
                      className={classes.container}
                      justify={"center"}
                      alignContent={"center"}>
                    <Suspense fallback={<LinearProgress/>}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                </Grid>
            </main>
        </>
    );
};

Auth.propTypes = {
    route: PropTypes.object
};

export default Auth;
