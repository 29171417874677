import {API, Auth} from "aws-amplify";

export const get = async (apiName, path, options) => {
    const session = await Auth.currentSession();
    return await API.get(apiName, path, options || {
        headers: options || {
            "Authorization": `Bearer ${session.idToken.jwtToken}`,
            "x-vfs-client-id": "*",
            "Content-Type": "application/json",
        },
    });
};

export const post = async (apiName, path, body, options) => {
    const session = await Auth.currentSession();
    return await API.post(apiName, path, {
        body,
        headers: options || {
            "Authorization": `Bearer ${session.idToken.jwtToken}`,
            "x-vfs-client-id": "*",
            "Content-Type": "application/json",
        },
    });
};

export const rawPost = async(apiName, path, body, options) => {
    return await API.post(apiName, path, {
        body,
        headers: options || {
            "x-vfs-client-id": "*",
            "Content-Type": "application/json"
        },
    });
}

export const patch = async (apiName, path, options) => {
    return await API.patch(apiName, path, options);
};

export const put = async (apiName, path, options) => {
    return await API.put(apiName, path, options);
};

export const del = async (apiName, path, options) => {
    return await API.del(apiName, path, options);
};
