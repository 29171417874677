import { Auth } from "aws-amplify";
import { registerUser } from "./account-service";

export const login = async (form) => {
    try {
        return await Auth.signIn(form.email, form.password);
    } catch (error) {
        return {
            error,
        }
    }
};

export const signOut = async () => {
    try {
        await Auth.signOut({ global: true });
        return { success: "User signed out successfully." };
    } catch (error) {
        return { error: "User was unable to be signed out." };
    }
};

/*
   User:
      - username
      - password
      - attributes
         - email
         - phone
         - firstName
         - lastName
 */
export const signUp = async (user) => {
    try {
        return await Auth.signUp({
            username: user.username,
            password: user.password,
            attributes: {
                "custom:firstName": user.firstName,
                "custom:lastName": user.lastName,
                "custom:phone": user.phone,
            },
        });
    } catch (error) {
        return {
            error,
        }
    }
};

export const userConfirmation = async (user) => {
    try {
        await Auth.confirmSignUp(user.email, user.confirmationCode);
        const confirmedUser = await Auth.signIn(user.email, user.password);
        return await registerUser(confirmedUser);
    } catch (error) {
        return {
            error,
        }
    }
};

export const resendConfirmationCode = async (email) => {
    try {
        return await Auth.resendSignUp(email);
    } catch (error) {
        return {
            error,
        }
    }
};

export const recoverPassword = async (email) => {
    try {
        return await Auth.forgotPassword(email);
    } catch (error) {
        return {
            error,
        }
    }
};

export const submitRecoverPassword = async (user) => {
    try {
        await Auth.forgotPasswordSubmit(user.email, user.confirmationCode, user.password);
        return {
            success: "Success!",
        }
    } catch (error) {
        return {
            error,
        }
    }
};

export const changePassword = async (oldPassword, newPassword) => {
    try {
        let user = await Auth.currentAuthenticatedUser();
        console.log(user);
        await Auth.changePassword(user, oldPassword, newPassword);
        return {
            success: 'Success!',
        }
    } catch (error) {
        return {
            error,
        }
    }
};

export const currentAuthenticatedUser = async () => {
    try {
        const user = await Auth.currentUserInfo();

        if (Object.entries(user).length === 0 && user.constructor === Object)
            return { error: "User not signed in." };
        else
            return user;

    } catch (error) {
        return { error: "User not signed in." };
    }
};
