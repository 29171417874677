export default {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_COGNITO_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    cookieStorage: {
        domain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
        path: "/",
        expires: 4,
        secure: false,
    },
};
