import React, {createContext, useReducer} from "react";
import userProfileReducer from "../reducers/userProfileReducer";
import {
    USER_PROFILE_LOAD_PROFILE,
    USER_PROFILE_SET_CLIENT_CONTEXT,
    USER_SIGN_OUT,
    SAVE_FORM_VALUES,
} from "../actions";
import {getUserProfile} from "../services/account-service";
import {signOut} from "../services/auth-service";

const initState = {
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    password: "",
    clientContext: null,
    clients: [],
};

export const GlobalContext = createContext({
    ...initState,
    loadUserProfile: () => {
    },
    signOutUser: () => {
    },
    saveFormValues: () => {
    },
    setClientContext: () => {
    },
});

const GlobalState = (props) => {
    const [userState, dispatch] = useReducer(userProfileReducer, {...initState});

    const loadUserProfile = async () => {
        const profile = await getUserProfile();
        dispatch({type: USER_PROFILE_LOAD_PROFILE, ...profile});
    };

    const signOutUser = async () => {
        await signOut();
        dispatch({type: USER_SIGN_OUT});
    };

    const saveFormValues = async (values) => {
        dispatch({type: SAVE_FORM_VALUES, ...values});
    };

    const setClientContext = (context) => {
        dispatch({type: USER_PROFILE_SET_CLIENT_CONTEXT, clientContext: context});
    };

    return (
        <GlobalContext.Provider
            value={{
                ...userState,
                loadUserProfile,
                saveFormValues,
                setClientContext,
                signOutUser,
            }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalState;
